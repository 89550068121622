/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Link } from 'src/components/core/links/link';
import {
  NewsletterFormData,
  subscribeNewsletter
} from 'src/requests/newsletter';
import { NewsletterPopup } from './newsletter-popup';
import { RoundButton } from 'src/components/core/buttons/round-button';
import { Text } from 'src/components/core/text';
import { Trans, useTranslation } from 'next-i18next';
import { Transition, TransitionStatus } from 'react-transition-group';
import { cookies } from 'src/core/constants/cookies';
import { media } from 'src/styles/utils/media';
import { parseCookies, setCookie } from 'nookies';
import { switchProp } from 'styled-tools';
import { toast } from 'src/providers/toast';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import closeSvg from 'src/assets/svg/close.svg';
import iconButton from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `newsletterShowDelay` constant.
 */

const newsletterShowDelay = 5000;

/**
 * `newsletterCookieOptions`.
 */

const getCookiesOptions = (days: number) => ({
  maxAge: days * 24 * 60 * 60,
  path: '/',
  secure: true
});

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  background-color: var(--color-white);
  box-shadow: var(--shadow-medium);
  display: grid;
  grid-gap: 1rem;
  left: 50%;
  max-width: 320px;
  opacity: 0;
  overflow: hidden;
  padding: 1rem 1.5rem 1.5rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition-default);
  transition-property: opacity, transform;
  width: 100%;
  z-index: var(--z-index-cookies-banner);

  ${media.min.sm`
    max-width: 480px;
  `}

  ${media.min.md`
    max-width: 748px;
  `}

  ${switchProp('transitionState', {
    entered: `
      opacity: 1;
      transform: translate(-50%, -50%);
    `,
    entering: `
      opacity: 0;
      transform: translate(-50%, -40%);
    `,
    exited: `
      opacity: 0;
      transform: translate(-50%, -50%);
    `,
    exiting: `
      opacity: 0;
      transform: translate(-50%, -40%);
    `
  })}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(RoundButton).attrs({
  colorTheme: 'greenOutlined',
  icon: closeSvg
})`
  border: none;
  color: currentColor;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.75);
`;

/**
 * `interpolatedLinks` component.
 */

const interpolatedLinks = [
  <Link
    href={process.env.NEXT_PUBLIC_PRIVATE_POLICY_URL}
    key={'privacyPolicyLink'}
    style={{ fontWeight: 'bold' }}
    target={'_blank'}
  />,
  <Link
    href={process.env.NEXT_PUBLIC_COOKIES_POLICY_URL}
    key={'cookiesPolicyLink'}
    style={{ fontWeight: 'bold' }}
    target={'_blank'}
  />
];

/**
 * Export `CookiesBanner` component.
 */

export function CookiesBanner() {
  const { t } = useTranslation(['common', 'footer']);
  const isMobile = useBreakpoint('ms', 'max');
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNewsletterOpen, setNewsletterIsOpen] = useState<boolean>(false);
  const [isPromoCodeVisible, setPromoCodeVisible] = useState<boolean>(false);
  const acceptCookiesCookie = useCallback(() => {
    setCookie(null, cookies.cookiesAccepted, 'true', getCookiesOptions(365));

    if (window.dataLayer !== undefined) {
      gtag('consent', 'update', {
        ad_personalization: 'granted',
        ad_storage: 'granted',
        ad_user_data: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted'
      } as Gtag.ConsentParams);
    }
  }, []);

  const acceptNewsletterCookie = useCallback(() => {
    setCookie(
      null,
      cookies.newsletterPopupClosed,
      'true',
      getCookiesOptions(180)
    );
  }, []);

  const handleCloseCookies = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setNewsletterIsOpen(true);
    }, newsletterShowDelay);
  }, []);

  const handleAcceptCookies = useCallback(() => {
    acceptCookiesCookie();
    handleCloseCookies();
  }, [acceptCookiesCookie, handleCloseCookies]);

  const handleCloseNewsletterPopup = useCallback(() => {
    setNewsletterIsOpen(false);
  }, []);

  const handleSubscribe = useCallback(
    async (data: NewsletterFormData, reset: () => void) => {
      try {
        await subscribeNewsletter(data);

        acceptNewsletterCookie();
        setPromoCodeVisible(true);
        reset();
        toast.success(t('footer.newsletter.network.success'));
      } catch (error) {
        toast.error(t('footer.newsletter.network.error'));
      }
    },
    [acceptNewsletterCookie, t]
  );

  useEffect(() => {
    const accepted = parseCookies()?.[cookies.cookiesAccepted] === 'true';
    const closedNewsletterPopup =
      parseCookies()?.[cookies.newsletterPopupClosed] === 'true';
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (!accepted) {
      setIsOpen(true);

      return;
    }

    if (!closedNewsletterPopup) {
      timeoutId = setTimeout(() => {
        setNewsletterIsOpen(true);
      }, newsletterShowDelay);

      return;
    }

    setNewsletterIsOpen(false);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      const accepted = parseCookies()?.[cookies.cookiesAccepted] === 'true';

      if (!accepted) {
        setIsOpen(true);

        return;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Transition
        in={isOpen}
        mountOnEnter
        timeout={350}
        unmountOnExit
      >
        {(state: TransitionStatus) => (
          <Wrapper transitionState={state}>
            <CloseButton onClick={handleCloseCookies} />

            <Text
              {...(!isMobile && { style: { marginBottom: '0.5rem' } })}
              variant={'display2'}
            >
              {t('cookies.title')}
            </Text>

            <Text
              {...(!isMobile && { style: { marginBottom: '1rem' } })}
              variant={isMobile ? 'small' : 'paragraph'}
            >
              <Trans
                components={interpolatedLinks}
                i18nKey={'cookies.description'}
                transSupportBasicHtmlNodes
              />
            </Text>

            <div>
              <Button
                colorTheme={'green'}
                icon={iconButton}
                onClick={handleAcceptCookies}
                size={isMobile ? 'small' : 'medium'}
                stretch
                style={{ textTransform: 'capitalize' }}
              >
                {t('actions.accept')}
              </Button>
            </div>
          </Wrapper>
        )}
      </Transition>

      <NewsletterPopup
        isOpen={isNewsletterOpen}
        isPromoCodeVisible={isPromoCodeVisible}
        onClose={handleCloseNewsletterPopup}
        onSubmit={handleSubscribe}
      />
    </>
  );
}
